//variables.scss

// FONTS //
$rRegular: 'robotoregular';
$rItalic: 'robotoitalic';
$rBold: 'robotobold';
$rBoldItalic: 'robotobold_italic';
$rLightItalic: 'robotolight_italic';
$rMediumItalic: 'robotomedium_italic';
$rBlackItalic: 'robotoblack_italic';

//COLORS
$main: #231f20;
$brand: #a72b44;
$snowy: #fff;
$borderStyle: #e6e6e6;
$missed: rgb(221, 69, 60);
