.mainNav {
	float: right;
	margin-top: 34px;
	// &-menu {
	// 	@include fontSize(0);
	// }
	&-item {
		float: left;
		margin-left: 40px;
	}
	&-link {
		//@include fontSize(12);
		position: relative;
		font-family: $rMediumItalic;
		text-transform: uppercase;
		color: $snowy;
		@include transition(color 300ms);
		padding: 7px 0;
		display: inline-block;
		background: none;
		&::after {
			content: '';
			@include box(0, 2px);
			@include transition(width 300ms);
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: $snowy;
		}
		&.dropdown-toggle {
			cursor: default;
			&::after {
				display: none;
			}
		}
		.no-touch &:hover {
			&::after {
				width: 100%;
			}
		}
		&.active {
			opacity: .5;
		}
	}
}
