/*================================================================
  SCALABLE AND REUSABLE MODULS 992
================================================================*/
.paragraph {
	padding-left: 0;
	&__small {
		padding-left: 0;
	}
}
.pageTitle {
	&__normal {
		@include fontSize(40);
	}
	&__large {
		@include fontSize(46);
	}
}
.sectionTitle, .pageTitle__normal {
	padding: 0 0 23px 0;
	// &::after {
	// 	content: '';
	// 	background-color: $brand;
	// 	position: absolute;
	// 	bottom: 0;
	// 	left: 0;
	// 	@include box(30px, 3px);
	// 	display: block;
	// }
}
.blockTitle {
  padding: 3px 0 3px 0;
}
.btnWrapper {
	padding-left: 0;
}
.block-992 {
	display: block;
}
.inline-992 {
	display: inline;
}
.inlineBlock-992 {
  display: inline-block;
}
.hide-992 {
	display: none;
}

// .slick-arrow {
//   display: none !important;
// }
// .slick-dots {
//   left: calc(50% - 360px);
// }
// .list.row {
// 	padding: 0 15px;
// }
// .formWrapper {
// 	&__main {
// 		padding: 60px 30px 50px;
// 	}
// }
//@import 'service/service992';
//@import 'banner/banner992';
