//================================================================
//  HEADER 767
//================================================================*/
.logo {
	&-img {
		&__header {
			width: 60px;
			height: auto;
		}
	}
}
@import 'nav/nav-767';
