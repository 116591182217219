//	mixins.scss

// BOX SIZE
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}
// css filters
@mixin grayscale($perc) {
  -webkit-filter: grayscale($perc); /* Safari 6.0 - 9.0 */
  filter: grayscale($perc);
}
// CSS3 FLEXBOX
@mixin flexbox() {
  display: -webkit-flex;
  display: flex;
}
@mixin inline-flex() {
  display: -webkit-inline-flex;
  display: inline-flex;
}
@mixin flex-direction($value: row) {
  -webkit-flex-direction: $value;
  flex-direction: $value;
}
@mixin flex-wrap($value: nowrap) {
  -webkit-flex-wrap: $value;
  flex-wrap: $value;
}
@mixin order($int: 0) {
  -webkit-order: $int;
  order: $int;
}
@mixin flex-grow($int: 0) {
  -webkit-flex-grow: $int;
  flex-grow: $int;
}
@mixin flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  flex-shrink: $int;
}
@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  flex-basis: $value;
}
@mixin flex($fg: 1, $fs: null, $fb: null) {
  -webkit-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}
@mixin justify-content($value: flex-start) {
  -webkit-justify-content: $value;
  justify-content: $value;
}
@mixin align-items($value: stretch) {
  -webkit-align-items: $value;
  align-items: $value;
}
@mixin align-self($value: auto) {
  -webkit-align-self: $value;
  align-self: $value;
}
@mixin align-content($value: stretch) {
  -webkit-align-content: $value;
  align-content: $value;
}

// CSS3 TRANSFORM 2D
// generic transform
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
// scale
@mixin scale($scaleX, $scaleY) {
   @include transform(scale($scaleX, $scaleY));
}
// translate
@mixin translate($x, $y) {
   @include transform(translate($x, $y));
}
@mixin translateX($x) {
   @include transform(translateX($x));
}
@mixin translateX($y) {
   @include transform(translateX($y));
}
// skew
@mixin skew($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

// CSS3 TRANSITIONS
// 1.transition-property
// 2.transition-duration
// 3.transition-timing-function
// 4.transition-delay
// all 0s ease 0s        the default
@mixin transition($args...) {
  -webkit-transition: $args;
  transition: $args;
}
@mixin transition-delay($delay) {
  -webkit-transition-delay: #{$delay}ms;
  transition-delay: #{$delay}ms;
}
// CSS3 ANIMATIONS
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
      @content;
  }
  @keyframes #{$animation-name} {
      @content;
  }
}
@mixin animation-duration($animation-duration) {
  -webkit-animation-duration: $animation-duration;
  animation-duration: $animation-duration;
}
@mixin animation-timing-function($animation-timing-function) {
  -webkit-animation-timing-function: $animation-timing-function;
  animation-timing-function: $animation-timing-function;
}
@mixin animation-delay($animation-delay) {
  -webkit-animation-delay: $animation-delay;
  animation-delay: $animation-delay;
}
@mixin animation-iteration-count($animation) {
  -webkit-animation-iteration-count: $animation-iteration-count;
  animation-iteration-count: $animation-iteration-count;
}
@mixin animation-direction($animation-direction) {
  -webkit-animation-direction: $animation-direction;
  animation-direction: $animation-direction;
}
@mixin animation-fill-mode($animation-fill-mode) {
  -webkit-animation-fill-mode: $animation-fill-mode;
  animation-fill-mode: $animation-fill-mode;
}
@mixin animation-play-state($animation-play-state) {
  -webkit-animation-play-state: $animation-play-state;
  animation-play-state: $animation-play-state;
}
// hierarchy for animation shortend property
// 1.animation-name
// 2.animation-duration
// 3.animation-timing-function
// 4.animation-delay
// 5.animation-iteration-count
// 6.animation-direction
// 7.animation-fill-mode
// 8.animation-play-state
// none 0 ease 0 1 normal none running    the default
@mixin animation($animation...) {
  -webkit-animation: $animation;
  animation: $animation;
}
// CSS3 COLUMNS
@mixin columnGap($gap) {
  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
}
@mixin columnCount($count) {
  -webkit-column-count: $count;
  -moz-column-count: $count;
  column-count: $count;
}

// FONTS
@mixin font($ff, $fontcolor, $px, $rem) {
  font-family: $ff;
  color: $fontcolor;
  font-size: $px;
  font-size: $rem;
}
@mixin fontSize($sizeValue) {
  font-size: $sizeValue + px;
  font-size: ($sizeValue / 10) + rem;
}

// BUTTONS
@mixin btn($bbg, $bh, $bcolor, $ptb: null, $plr: $ptb) {
  background-color: $bbg;
  border: $bh solid $bcolor;
  padding: $ptb $plr;
}

// POSITIONING
@mixin pos($pos: absolute, $top: auto, $left: auto, $right: null, $bottom: null) {
  position: $pos;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}
///// other mixins
@mixin linear-gradient($from, $to, $ie: $useIEFilters) {
  @if $ie != 1 {
    background-color: $to;
  }

  background-image: -moz-linear-gradient($from, $to) !important;
  background-image: -o-linear-gradient($from, $to);
  background-image: -ms-linear-gradient($from, $to);
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, $from),color-stop(1, $to));
  background-image: -webkit-linear-gradient($from, $to);

  @if $ie == 1 {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}');
    -ms-filter: quote(progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}'));
  }
}
