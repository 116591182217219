/*================================================================
  SCALABLE AND REUSABLE MODULS 1200
================================================================*/
// @import 'panel/panel1560';
// @import 'story/story1560';
// .slick-next {
//   right: 15px;
// }
// .slick-prev {
//   left: 15px;
// }
// .slick-dots {
//   left: calc(50% - 570px);
// }
// .formWrapper {
// 	&__snowy {
// 		.input-group {
// 			width: 490px;
// 		}
// 	}
// }
