.footer {
	padding: 20px 0 30px;
	text-align: center;
	&-contact {
		margin: 0 0 20px;
		float: none;
		text-align: center;
	}
	.paragraph {
		padding-left: 0;
	}
	.mainNav {
		float: none;
		width: 100%;
		&-item {
			margin: 0;
			display: block;
			float: none;
			& + .mainNav-item {
				margin-left: 0;
			}
		}
		&-link {
			@include fontSize(12);
		}
	}
	.logo {
		float: none;
		margin: 25px auto 0;
		display: block;
	}
}
.copyright {
	float: none;
	&-text {
		float: none;
		margin: 7px 0 0 0;
	}
}
.social {
	float: none;
	&-heading {
		//margin: 15px 0 0 0;
		float: none;
	}
	&-nav {
		float: none;
		margin: 10px 0 0 0;
	}
	&-item {
		float: none;
		display: inline-block;
		margin-right: 0;
		& + .social-item {
			margin-left: 15px;
		}
	}
}
.dev {
	margin: 20px 0 0 0;
	float: none;
	&-text {
		float: none;
		margin: 2px 12px 0 0;
	}
	&-logo {
		float: none;
	}
}
.phone__small {
	@include fontSize(22);
}
