.doorTypes {
	&-nav {
		margin: 0 auto;
		width: 480px;
	}
	&-item {
		width: 100%;
		display: block;
		margin-bottom: 10px;
	}
	&-intro {
		padding: 10px 15px 15px 15px;
		height: auto;
	}
}
.profService {
	margin-bottom: 30px;
}
.advantages {
	margin: 10px 0 0;
}
.advantage {
	width: 100%;
	display: inline-block;
	//vertical-align: middle;
	height: 100px;
	//background-color: rgba(0,0,0,.4);
	//background-blend-mode: multiply;
	//background-size: cover;
	//color: $snowy;
	padding: 10px 15px;
	&::before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}
	&__overflow {
		position: static;
	}
	.blockTitle {
		display: inline-block;
		vertical-align: middle;
	}
}
.reviews-nav, .dots-nav, .review-number {
	position: static;
	float: right;
	margin-top: 30px;
}
.dots-nav {
	margin-top: 40px;
}
.review-number {
	margin: 40px 20px 0 0;
}
.partners {
	padding-top: 0;
	&-nav {
		margin: 0 -15px;
	}
}
.partner {
	height: 40px;
	margin-top: 40px;
}
.home-contact {
	position: static;
	//left: calc(50vw - 765px);
	padding: 25px 20px 15px;
	width: 100%;
	.sectionTitle {
		margin-bottom: 20px;
	}
	&-item {
		margin-bottom: 15px;
	}
}
