// .block-mainPhoto {
// 	@extend %center-block;
// 	max-width: 100%;
// 	height: 100%;
// }
// .block-imgWrapper {
// 	height: 200px;
// 	//overflow: hidden;
// 	margin-top: -74px;
// 	margin-bottom: 40px;
// 	padding-left: 0;
// 	&__normal {
// 		margin-top: 0;
// 		height: 278px;
// 		padding: 78px 0 0;
// 		// .block-mainPhoto {
// 		// 	margin-top: 78px;
// 		// }
// 	}
// }
// .block-photo {
// 	max-width: 100%;
// 	width: auto;
// 	height: 130px;
// 	margin: 0 0 22px -15px;
// }
.colors, .styles {
	margin-left: -15px;
}
.color {
	margin-top: 30px;
	float: left;
	width: 20%;
	&-name {
		margin-bottom: 0;
		font-family: $rBoldItalic;
		@include fontSize(11);
	}
	&-view {
		@include box(98px);
		display: block;
		margin-bottom: 6px;
	}
}
.tm {
	@include fontSize(8);
}
.trade {
	@include fontSize(11);
	top: -0.3em;
}
.styles {
	&__reversed {
		// .style-view {
		// 	height: auto;
		// }
		.style:first-child {
			margin-top: 0;
		}
	}
}
.style {
	margin-top: 30px;
	&-name {
		margin-bottom: 0;
		font-family: $rBoldItalic;
		@include fontSize(11);
	}
	&-view {
		@include box(auto, 98px);
		max-width: 100%;
		margin-bottom: 6px;
	}
}
.list {
	&-item {
		& + .list-item {
			margin-top: 20px;
		}
		&-title {
			margin-bottom: 7px;
			&::after {
				width: 7px;
				background-color: $brand;
				height: 7px;
				top: 11px;
			}
		}
	}
}
.single-view {
	margin-top: 30px;
}
.sectionTitle-img {
	margin-left: 100px;
	vertical-align: top;
}
// .smallWrapper {
// 	padding: 0;
// 	height: 190px;
// 	position: absolute;
// 	bottom: 0;
// 	right: 0;
// 	// img {
// 	// 	height: 100%;
// 	// 	max-width: 100%;
// 	// }
// }
