.doorTypes {
	&-intro {
		padding: 15px 15px 23px;
		height: 165px;
		&__large {
			padding: 25px 15px 23px;
			height: 175px;
		}
	}
	.blockTitle {
		@include fontSize(15);
	}
}
.advantage {
	padding: 10px 25px;
	// .blockTitle {
	// }
}
.review {
	padding: 0 15px;
}
.partner {
	padding: 0 15px;
}
.home-contact {
	left: calc(50vw - 360px);
	width: 40%;
}
