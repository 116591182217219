/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.reviews-nav {
  position: absolute;
  top: 0;
  right: 0;
}
.slick-arrow {
  background: none;
  border: 1px solid #802134;
  color: $snowy;
  padding: 7px 13px;
  line-height: 1;
  @include fontSize(22);
  @include transition(border 300ms);
  .no-touch &:hover {
    border-color: #c67585;
    border-bottom: 2px solid $snowy;
  }
  &.slick-disabled {
    //opacity: .5;
    pointer-events: none;
  }
}
.dots-nav {
  position: absolute;
  top: 10px;
  right: 140px;
  //@include translate(-100%, 0);
  button {
    //display: none;
    border: none;
    background-color: #802134;
    @include box(2px, 20px);
    font-size: 0;
    overflow: hidden;
    @include transition(height 300ms, background-color 300ms);
  }
  li {
    display: inline-block;
    vertical-align: top;
    line-height: 1;
    cursor: pointer;
    padding-right: 5px;
    margin-right: 10px;
  }
  .slick-active {
    button {
      background-color: $snowy;
      width: 3px;
    }
  }
}
.review-number {
  position: absolute;
  top: 10px;
  right: 120px;
  @include fontSize(16);
}
