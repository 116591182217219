.since {
	margin-top: 50px;
	&-text {
		text-transform: uppercase;
		font-family: $rItalic;
		@include fontSize(24);
		margin-bottom: 60px;
	}
	&-date {
		@include fontSize(41);
		line-height: 0.7;
    display: inline-block;
    border-bottom: 2px solid #fff;
	}
}
.contact {
	&-list {
	font-size: 0;
	}
	&-item {
		margin: 20px 0 0 0;
		display: inline-block;
		vertical-align: top;
		width: 50%;
		position: relative;
		padding-left: 25px;
		&::before {
			content: '';
	    background-color: #802134;
	    position: absolute;
	    top: 25px;
	    left: -15px;
	    width: 3px;
	    height: 30px;
	    display: block;
		}
	}
	&-title {
		@include fontSize(12);
	}
	&-address {
		@include fontSize(15);
	}
}
.email {
	@include fontSize(16);
	color: $snowy;
	@include transition(opacity 300ms);
	padding-top: 4px;
	display: inline-block;
	.no-touch &:hover {
		opacity: .5;
	}
}
.workingHours {
	@include fontSize(20);
	display: inline-block;
	padding-top: 2px;
}
.pm {
	text-transform: uppercase;
	font-size: 55%;
}
.fax {
	pointer-events: none;
}
