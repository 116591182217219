.colors, .styles {
	margin-left: 0;
	font-size: 0;
}
.color {
	width: 20%;
	display: inline-block;
	float: none;
	vertical-align: top;
	&-view {
		@include box(85px);
	}
}
.insulatedDoors {
	.overflowed::before {
		display: none;
	}
}
.styles {
	&__reversed {
		margin-right: 0;
	}
}
.narrow992 {
	max-width: none;
}
.serviceQuality {
	margin-top: 30px;
}
// .block-imgWrapper {
// 	margin: -40px 15px 20px auto;
// 	width: 60%;
// 	float: none;
// 	height: 100px;
// }
// .block-photo {
// 	margin-left: 0;
// }
// .aluminium, .styleSection {
// 	margin-top: 30px;
// }
// .noTop767 {
// 	padding-top: 0;
// }
// .largeBottom767 {
// 	padding-bottom: 70px;
// }
