.header {
	.mainNav {
		//max-height: 0;
		width: 480px;
		float: none;
		position: absolute;
		// right: 50px;
		right: -100%;
		top: 10px;
		background-color: $brand;
		z-index: 2;
		-moz-column-count: 3;
	  -webkit-column-count: 3;
	  column-count: 3;
		overflow: hidden;
		margin-right: 10px;
		// padding-bottom: 0;
		padding-bottom: 20px;
		// @include transition(max-height 500ms, padding 300ms);
		@include transition(right 300ms);
		&-item {
			//width: 140px;
			float: none;
			display: inline-block;
	  	width: 100%;
		}
		&.open {
			right: 50px;
			//max-height: 200px;
			// padding-bottom: 20px;
		}
		&-toggle {
			float: right;
			background: none;
			border: none;
			margin-top: 38px;
			display: block;
			//@include transition(transform 500ms);
			&.active {
				.icon-bar__top {
					@include rotate(45);
				}
				.icon-bar__middle {
					@include scale(0,0);
				}
				.icon-bar__bottom {
					@include rotate(-45);
				}
			}
		}
	}
}
.icon-bar {
	@include transition(width 500ms, transform 500ms, opacity 500ms);
	@include box(21px, 3px);
	display: block;
	//border-radius: 2px;
	background-color: #fff;
	& + .icon-bar {
		margin-top: 4px;
	}
}
.icon-bar__top {
	-ms-transform-origin: left top; /* IE 9 */
	-webkit-transform-origin: left top; /* Chrome, Safari, Opera */
	transform-origin: left top;
}
.icon-bar__middle {
	@include scale(1,1);
}
.icon-bar__bottom {
	-ms-transform-origin: left bottom; /* IE 9 */
	-webkit-transform-origin: left bottom; /* Chrome, Safari, Opera */
	transform-origin: left bottom;
}
