/*================================================================
  SCALABLE AND REUSABLE MODULS 480
================================================================*/
//@import 'panel/panel480';
.imgWrapper {
	&__lg {
		//margin-bottom: 20px;
		height: 100px;
	}
	&__md {
		margin: 0;
	}
}
