/*================================================================
  SCSS PLACEHOLDERS
================================================================*/

//prevent user select
%noSelect{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//for masks
%mask {
  @include pos(absolute,0,0);
  background-color: transparent;
  @include box(100%);
}
//for one line overflowing texts
%overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
// clear floating
%clearfix {
  &::before, &::after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
// Forms //
%placeholder__main {
  &::-webkit-input-placeholder {
    //@include font($OSregular, $main, 14px, 1.4rem);
  }
  &:focus::-webkit-input-placeholder {
    color: transparent;
  }
  &::-moz-placeholder {
		//@include font($OSregular, $main, 14px, 1.4rem);
    opacity: 1;
  }
  &:focus::-moz-placeholder { color: transparent; } /* FF 19+ */

  &:-ms-input-placeholder {
		//@include font($OSregular, $main, 14px, 1.4rem);
  }
  &:focus:-ms-input-placeholder { color: transparent; } /* IE 10+ */
}
%placeholder__snowy {
  &::-webkit-input-placeholder {
    //@include font($OSregular, $snowy, 14px, 1.4rem);
  }
  &:focus::-webkit-input-placeholder {
    color: transparent;
  }
  &::-moz-placeholder {
		//@include font($OSregular, $snowy, 14px, 1.4rem);
    opacity: 1;
  }
  &:focus::-moz-placeholder { color: transparent; } /* FF 19+ */

  &:-ms-input-placeholder {
		//@include font($OSregular, $snowy, 14px, 1.4rem);
  }
  &:focus:-ms-input-placeholder { color: transparent; } /* IE 10+ */
}

//positioning
%center-block {
	@include pos(relative, 50%, 50%);
	@include translate(-50%, -50%);
}
%fit-contain-img {
	max-width: 100%;
	max-height: 100%;
	@extend %center-block;
}
// its also posible to use center-block - fit-img and center-block - contain-img pairs together
%fit-img {
  @include box(100%, auto);
}
%contain-img {
  @include box(auto, 100%);
}
// %bordered {
//
// }
// %activeBrand {
//   @include transition(color 300ms);
//   text-decoration: underline;
//   .no-touch &:hover, &:active, &.active {
//     text-decoration: none;
//   }
//   .no-touch &:active, &:active, &.active {
//     color: $brand;
//   }
// }
