@font-face {
  font-family: 'icomoon';
  //src:  url('fonts/icomoon.eot?2689oi');
  src:  //url('fonts/icomoon.eot?2689oi#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?2689oi') format('truetype'),
    url('../fonts/icomoon.woff?2689oi') format('woff'),
    url('../fonts/icomoon.svg?2689oi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.1;
	text-align: center;
  width: auto;
  padding-left: 1px;
	//@include fontSize(23);
  //transform: translateZ(0);
  //-webkit-transform: translateZ(0);

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $snowy;
}
.icon-facebook:before {
  content: "\e900";
}
.icon-google-plus:before {
  content: "\e901";
}
.icon-long-arrow-left:before {
  content: "\e902";
}
.icon-long-arrow-right:before {
  content: "\e903";
}
.icon-twitter:before {
  content: "\e904";
}
