.footer {
	background-color: $main;
	padding: 30px 0;
	color: $snowy;
	.mainNav {
		float: left;
		margin: 0;
		&-link {
			@include fontSize(10);
			&::after {
				height: 1px;
			}
		}
		&-item {
			margin: 0 45px 0 0
		}
	}
	.dropdown-menu {
		background-color: $main;
	}
	&-contact {
		float: right;
		margin-bottom: 70px;
		text-align: right;
	}
	&-footer {
		clear: both;
	}
}
.phone {
	color: $snowy;
	opacity: 1;
	@include transition(opacity 300ms);
	display: block;
	.no-touch &:hover {
		opacity: .7;
	}
	&__small {
		@include fontSize(19);
		margin: 3px 0 30px;
	}
	&__large {
		@include fontSize(32);
		line-height: 1;
	}
}
.copyright {
	float: left;
	&-text {
		float: left;
		margin: 15px 0 0 8px;
	}
}
.social {
	float: right;
	&-heading {
		margin: 15px 0 0 0;
		float: left;
	}
	&-nav {
		float: left;
		margin: 14px 0 0 6px;
	}
	&-item {
		float: left;
		margin-right: 15px;
	}
	&-link {
		display: block;
		opacity: .5;
		@include transition(opacity 300ms);
		@include fontSize(13);
		.no-touch &:hover {
			opacity: 1;
		}
	}
}
.dev {
	margin: 15px 0 0 0;
	float: right;
	&-text {
		float: left;
		margin: 2px 12px 0 0;
	}
	&-logo {
		float: left;
	}
}
