.doorTypes {
	&-intro {
		height: 180px;
		&__large {
			height: 190px;
		}
	}
}
.home-contact {
	left: calc(50vw - 470px);
	width: 33%;
	padding: 20px 20px 0;
}
