.footer {
	.mainNav {
		width: 70%
		// &-item {
		// 	margin-right: 0;
		// 	& + .mainNav-item {
		// 		margin-left: 26px;
		// 	}
		// }
	}
	// &-contact {
	// 	margin: 30px 0 50px;
	// }
}
.phone__small {
	margin: 0 0 11px
}
