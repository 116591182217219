/*================================================================
  SCALABLE AND REUSABLE MODULS 767
================================================================*/
// body {
//   //padding-top: 40px;
//   //@include fontSize(16px, 1.6rem);
// }
.section {
	padding: 40px 0;
	&-block {
		padding-top: 40px;
	}
	&__large {
		padding-bottom: 70px;
	}
	// &__xLarge {
	// 	padding-bottom: 100px;
	// }
	&__garage {
		.innerflowed {
			padding-top: 10px;
		}
		.section-block {
			padding-top: 0;
		}
	}
	&-img {
		padding-top: 0;
		&.pullRight {
			float: none;
			margin: -15px 0 15px auto;
		}
	}
}
.noTop {
	padding-top: 0;
}
.bottomSpace {
	margin-bottom: 20px;
}
.overlapped {
	margin-top: -40px;
}
.topSpace {
	margin-top: 30px;
}

.innerflowed__small::after {
    width: 50%;
}
.block {
	&__fixed {
		position: static;
		float: none;
		margin: 20px auto 0;
	}
}
.imgWrapper__lg {
  margin-bottom: 20px;
}
.textList {
	padding-left: 0;
}
// .noRight {
// 	margin-right: 0;
// }
// .noTop {
// 	margin-left: 0;
// }
// .paragraph {
// 	padding-left: 0;
//   &__small {
//     padding-left: 0;
//   }
// }
.sectionTitle {
  margin-bottom: 20px;
	padding-bottom: 15px;
	line-height: 1.2;
}
.pageTitle {
	margin: 0 0 23px;
  @include fontSize(28);
}
.sectionTitle, .pageTitle__normal {
	&::after {
		left: -15px;
	}
}
.btn {
  &Wrapper {
    margin-top: 25px;
  }
  &__brand {
    min-width: 130px;
    padding: 11px 5px;
  }
  & + .btn {
    margin-left: 26px;
  }
}
#map {
	height: 350px;
}
// .list {
//   &-item {
//     margin-bottom: 0;
//     & + .list-item {
//       margin-top: 15px;
//     }
//     &::before {
//       position: static;
//       display: inline-block;
//       vertical-align: top;
//       margin: 2px 3px 0 0
//     }
//   }
//   &.row {
//     padding: 0;
//   }
//   &:not(.row) {
//     .list-item {
//       padding: 0;
//     }
//   }
// }
.block-767 {
	display: block;
}
.inline-767 {
  display: inline;
}
.inlineBlock-767 {
  display: inline-block;
}
.hide-767 {
	display: none;
}

// .slick-dots {
//   bottom: 22px;
//   left: 50%;
//   @include translate(-50%, 0);
// }
// #map {
//   height: 200px;
// }
// .formWrapper {
//   height: auto;
// 	&__snowy {
//     padding: 30px 35px 40px;
//   }
//   &__main {
//     padding: 30px 20px 40px;
//   }
// 	.btn {
//     width: 100%;
//   }
// }
//@import 'service/service767';
//@import 'banner/banner767';
