//================================================================
  //SCALABLE AND REUSABLE MODULS 1200
//================================================================*/
// .slick-next {
//   right: 0;
// }
// .slick-prev {
//   left: 0;
// }
// .slick-dots {
//   left: calc(50% - 470px);
// }
// .formWrapper {
// 	&__snowy {
//     padding-left: 50px;
//     padding-right: 50px;
// 		.input-group {
// 			width: auto;
// 		}
// 	}
// }
