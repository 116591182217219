.color {
	width: 16%;
}
// .block-imgWrapper {
// 	overflow: hidden;
// }
// .block-mainPhoto {
// 	max-width: none;
// }
.aluminium, .styleSection {
	margin-top: 50px;
}
.narrow992 {
	max-width: 50%;
	display: inline-block;
}
