/*==============
  FONTS
==============*/
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 20, 2017 */
@font-face {
  font-family: 'robotoblack_italic';
  src: url('../fonts/roboto-blackitalic.woff2') format('woff2'),
       url('../fonts/roboto-blackitalic.woff') format('woff'),
       url('../fonts/roboto-blackitalic.ttf') format('truetype'),
       url('../fonts/roboto-blackitalic.svg#robotoblack_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotobold';
  src: url('../fonts/roboto-bold.woff2') format('woff2'),
       url('../fonts/roboto-bold.woff') format('woff'),
       url('../fonts/roboto-bold.ttf') format('truetype'),
       url('../fonts/roboto-bold.svg#robotobold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotobold_italic';
  src: url('../fonts/roboto-bolditalic.woff2') format('woff2'),
       url('../fonts/roboto-bolditalic.woff') format('woff'),
       url('../fonts/roboto-bolditalic.ttf') format('truetype'),
       url('../fonts/roboto-bolditalic.svg#robotobold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotoitalic';
  src: url('../fonts/roboto-italic.woff2') format('woff2'),
       url('../fonts/roboto-italic.woff') format('woff'),
       url('../fonts/roboto-italic.ttf') format('truetype'),
       url('../fonts/roboto-italic.svg#robotoitalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotolight_italic';
  src: url('../fonts/roboto-lightitalic.woff2') format('woff2'),
       url('../fonts/roboto-lightitalic.woff') format('woff'),
       url('../fonts/roboto-lightitalic.ttf') format('truetype'),
       url('../fonts/roboto-lightitalic.svg#robotolight_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotomedium_italic';
  src: url('../fonts/roboto-mediumitalic.woff2') format('woff2'),
       url('../fonts/roboto-mediumitalic.woff') format('woff'),
       url('../fonts/roboto-mediumitalic.ttf') format('truetype'),
       url('../fonts/roboto-mediumitalic.svg#robotomedium_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotoregular';
  src: url('../fonts/roboto-regular.woff2') format('woff2'),
       url('../fonts/roboto-regular.woff') format('woff'),
       url('../fonts/roboto-regular.ttf') format('truetype'),
       url('../fonts/roboto-regular.svg#robotoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*================================================================
  RESET DEFAULT BROWSER CSS, HELPERS THAT USES MIXINS, PLACHOLDERS
================================================================*/
@import "base/variables";

@import "base/mixins";

@import "base/placeholders";

@import "base/reset";

/*==============
  THIRD PARTY
=================*/
/* svg icons */
@import "thirdParty/icomoon/icomoon";

/* Slick */
@import "thirdParty/slick/slick";

/* tinyscrollbar */
//@import "thirdParty/scrollbar/tinyscrollbar";

/*========================================================
	CSS BASE ELEMENTS, SCALABLE AND REUSABLE MODULS
=========================================================*/
@import "base/modules/modules";

@import "base/baseElements";

@import "base/headlines";

//@import "base/forms";


/*==========================
  MAIN PARTIALS OF PAGES
===========================*/
@import "partials/body";

/*==========================
  Moduls that overwrite other modules
===========================*/
@import "base/higherOrderModuls";

/*==========================
  MEDIA QUERIES
===========================*/
@import "partials/media-desktop-1560";
@import "partials/media-desktop-1200";
@import "partials/media-tablet-992";
@import "partials/media-mobile-767";
@import "partials/media-mobile-480";
