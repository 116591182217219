.color {
	width: 25%;
}
.sectionTitle {
	letter-spacing: -.5px;
}
.sectionTitle-img {
	margin-left: 25px;
}
// .smallWrapper {
// 	overflow: hidden;
// 	.block-mainPhoto {
// 		max-width: none;
// 	}
// }
// .styles {
// 	&__reversed {
// 		.style-view {
// 			height: 98px;
// 		}
// 		// .style:first-child {
// 		// 	margin-top: 0;
// 		// }
// 	}
// }
