/************ Headlines ************/
.pageTitle {
	text-transform: uppercase;
	line-height: 1.2;
	margin-bottom: 40px;
	&__normal {
		@include fontSize(48);
	}
	//color: $snowy;
	//margin-bottom: 50px;
	&__large {
		margin-top: 50px;
		font-family: $rLightItalic;
		@include fontSize(52);
	}
}
.sectionTitle {
	//font-family: $rItalic;
	@include fontSize(24);
	text-transform: uppercase;
	//padding-left: 25px;
	margin-bottom: 40px;
}
.sectionTitle, .pageTitle__normal {
	position: relative;
	font-family: $rItalic;
	padding: 0 0 23px 25px;
	&::after {
		content: '';
		background-color: $brand;
		position: absolute;
		bottom: 0;
		left: -30px;
		@include box(30px, 3px);
		display: block;
	}
}
.blockTitle {
	padding: 3px 0 3px 25px;
	@include fontSize(16);
	//border-left: 3px solid $borderStyle;
	margin-bottom: 15px;
	position: relative;
	&__upper {
		font-family: $rMediumItalic;
		text-transform: uppercase;
	}
	&__lower {
		text-transform: capitalize;
	}
	&::after {
		content: '';
		background-color: $borderStyle;
		position: absolute;
		top: 0;
		left: -15px;
		@include box(3px, 30px);
		display: block;
	}
	// &__bordered {
	//
	// }
	// font-family: $OSsemiBold;
	// &__upper {
	// 	text-transform: uppercase;
	// 	margin-bottom: 13px;
	// }
}
.smallTitle {
	font-family: $rLightItalic;
	text-transform: uppercase;
	margin-bottom: 6px;
}
