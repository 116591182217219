.dropdown {
	position: relative;
	.no-touch &:hover, 	&.open {
		.dropdown-menu {
			//@include transition(max-height 300ms);
			max-height: 150px;
			padding-bottom: 25px;
			//height: auto;
			//overflow: visible;
		}
	}
}
.dropdown-menu {
	position: absolute;
	max-height: 0px;
	//height: 0;
	padding: 0 30px;
	left: -30px;
	top: 100%;
	z-index: 1;
	background-color: $brand;
	@include transition(max-height 300ms, padding 300ms);
	min-width: 170px;
  overflow: hidden;
}
.dropdown-item {
	margin-top: 4px;
}
