.header {
	.mainNav {
		-moz-column-count: 2;
	  -webkit-column-count: 2;
	  column-count: 2;
		width: 100%;
		margin: 0;
		//right: auto;
		//left: 0;
		top: 60px;
		&.open {
			right: 0;
		}
		&-toggle {
			margin-top: 9px;
		}
		&-item {
			margin-left: 15px;
		}
	}
}
