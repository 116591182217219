@media (min-width: 768px) {
	// .pullRight {
	//   float: right;
	// }
}

@media (max-width: 767px) {
	@import "../base/modules/modules-767";
	@import "1header/header-767";
	@import "2content/content-767";
	@import "3footer/footer-767";
}
