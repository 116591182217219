/*================================
	* CSS base Elements
=================================*/
html {
  //height: 100%;
  overflow-x: hidden;
}
body {
  @include font($rRegular, $main, 12px, 1.2rem);
  background-color: $snowy;
  line-height: 1.451;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  //min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  //padding-top: 75px;
  //padding: 50px 0 100px 0; // requires to be equal to header and footer height
  // &.no-scroll {
  //   overflow: hidden;
  //   position: fixed;
  // }
}
::-moz-selection {
  color: $snowy;
  background-color: $brand;
}
::selection {
  color: $snowy;
  background-color: transparentize($brand, .01);
}
.section {
  padding: 70px 0;
  &-block {
    padding-top: 70px;
  }
  &-img {
    padding-top: 13px;
  }
}
.overflowed {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    @include box(100%, 10px);
    background-color: $snowy;
  }
}
.innerflowed {
  position: relative;
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		right: -1px;
		height: 10px;
		background-color: $snowy;
	}
	&__small {
		&::after {
			width: 33.33%;
		}
	}
  &__middle {
    &::after {
			width: 50%;
		}
  }
  &__large {
		&::after {
			width: 66.66%;
		}
	}
}
.overlapped {
  margin-top: -70px;
}
a {
  //color: $dark;
  text-decoration: none;
  // text-transform: uppercase;
  // @include fontSize(14px, 1.4rem);
  // letter-spacing: 1.5px;
  //@include transition(color 200ms ease);
  // .no-touch &:hover {
  // 	outline: 0;
  //  color: $link-hover-color;
  // }
  // .no-touch &:active, &:active {
  //   outline: 0;
  //   color: $link-active-color;
  // }
  &:focus {
  	outline: 0;
  }
  // &.button {
  //   &.disabled,
  //   fieldset[disabled] & {
  //     pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
  //   }
  // }
  &.active, &.disabled {
    cursor: default;
    pointer-events: none;
  }
}
// .link {
//   @include transition(color 300ms);
//   &__dark {
//     color: $main;
//   }
//   &__snowy {
//     color: $snowy;
//   }
//   .no-touch &:hover {
//     color: $brand;
//   }
//   &__brand {
//     color: $brand;
//     .no-touch &:hover {
//       color: $main;
//     }
//   }
// }
.textLink {
  color: $brand;
  text-decoration: none;
  @include transition(color 300ms);
  .no-touch &:hover, &:active {
    text-decoration: underline;
  }
}
// .imgMask {
// 	//@include transition(background-color 500ms);
// 	position: absolute;
// 	@include box(100%);
//   //width: calc(100% - 30px);
//   //height: calc(100% - 14px);
// 	left: 0;
// 	top: 0;
// 	background-color: rgba(0,0,0,.1);
//   background-clip: content-box;
// }
ul {
	list-style: none;
}
.textList {
  margin: 20px 0;
  padding-left: 25px;
  &-item {
    margin-bottom: 7px;
  }
}
// .list {
//   &-item {
//     margin-bottom: 15px;
//     position: relative;
//     padding-left: 15px;
//     &:before {
//       content: '-';
//       position: absolute;
//       top: 2px;
//       left: 5px;
//       line-height: 1;
//     }
//   }
  // &-link {
  //
  // }
//}
.paragraph {
  line-height: 1.5;
  @include fontSize(13);
  padding-left: 25px;
  //font-family: $pnLight;
  // &__snowy {
  //   color: $snowy;
  // }
  // &__large {
  //
  // }
  &__small {
    @include fontSize(12);
    padding-left: 25px;
  }
  &__xSmall {
    @include fontSize(10);
    opacity: .5;
  }
  & + .paragraph {
    margin-top: 22px;
  }
}

// .btnWrapper {
//   text-align: center;
//   margin-bottom: 30px;
//   &__tS {
//     margin-top: 50px;
//   }
// }
// #map {
// 	@include box(100%, 500px);
// }
.btn {
  &Wrapper {
    margin-top: 35px;
    padding-left: 25px;
  }
  @include transition(background-color 300ms, border-color 300ms, color 300ms, opacity 300ms);
  @extend %noSelect;
  background-color: transparent;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block;
  touch-action: manipulation;
  cursor: pointer;
  //margin-top: 35px;
  //border-radius: 3px;
  font-family: $rBoldItalic;
  text-transform: uppercase;
  padding: 11px 23px;
  position: relative;
  z-index: 1;
  &::after {
    content: '';
    @include box(100%, 2px);
    @include transition(height 300ms);
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  & + .btn {
    margin-left: 14px;
  }
  .no-touch &:hover {
    &::after {
      height: 100%;
    }
  }
  &__main {
    color: $main;
    border: 1px solid #dedede;
    &::after {
      background-color: $brand;
    }
    //border-bottom: 2px solid $brand;
    .no-touch &:hover {
      //background-color: $brand;
      //border-color: $brand;
      color: $snowy;
    }
  }
  &__brand {
    color: $snowy;
    min-width: 140px;
    border: 1px solid #c67585;
    &::after {
      background-color: $snowy;
    }
    //border-bottom: 2px solid $snowy;
    .no-touch &:hover {
      //background-color: $snowy;
      //border-color: $snowy;
      color: $brand;
    }
  }
  // &__dark {
  //   .no-touch &:hover {
  //     color: $main;
  //   }
  // }
  // &.disabled {
  //   opacity: 0.5;
  //   cursor: default;
  //   pointer-events: none;
  // }
}
#map {
  height: 500px;
}
.mapWrapper {
  position: relative;
}
.gm-style-mtc {
  display: none;
}
//// HELPER CLASSES ///////
.clearfix {
  &::before, &::after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
// dealing with images
// .imgWrapper {
//   overflow: hidden;
//   // img {
//   //   //@extend %fit-contain-img;
//   //   @extend %fit-img;
//   //   //@extend %contain-img;
//   // }
// }
.img {
  &__centerX {
    position: relative;
    left: 50%;
    @include translateX(-50%);
  }
  &__centerY {
    position: relative;
    top: 50%;
    //@include translateY(-50%);
  }
  &__center {
    @extend %center-block;
  }
  &__contain {
    @include box(auto, 100%);
  }
  &__fit {
    @include box(100%, auto);
  }
  &__fitContain {
    max-width: 100%;
  	max-height: 100%;
  }
  &Wrapper {
    overflow: hidden;
    &__xxs {
      height: 98px;
    }
    &__xs {
      height: 130px;
    }
    &__sm {
      height: 155px;
    }
    &__md {
      height: 190px;
    }
    &__lg {
      height: 200px;
    }
  }
}
.block {
  &__fixed {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
// .pullRight {
//   float: right;
// }
// .pullLeft {
//   float: left;
// }
// .alignCenter {
//   text-align: center;
// }
// .alignRight {
//   text-align: right;
// }
.section {
  &__brand {
    background-color: $brand;
    color: $snowy;
    .sectionTitle, .pageTitle__normal {
    	&::after {
    		background-color: $snowy;
      }
    }
    .blockTitle {
      &::after {
    		background-color: #802134;
      }
    }
    .textLink {
      color: #e595a5;
    }
    .innerflowed::after {
      background-color: $brand;
    }
  }

}
.blackItalic {
  font-family: $rBlackItalic;
}
.mediumItalic {
  font-family: $rMediumItalic;
}
.bold, strong {
  font-family: $rBold;
}
.relative {
  position: relative;
}
.boldItalic {
  font-family: $rBoldItalic;
}
.noLeft {
  margin-left: -15px;
}
.noRight {
  margin-right: -15px;
}
.noTop {
  padding-top: 0;
}
.bottomSpace {
	margin-bottom: 30px;
}
.topSpace {
  margin-top: 50px;
}
// .noRightSpace {
//   padding-right: 0;
// }
