.about-img {
	float: none;
	margin-bottom: 25px;
}
.section {
	.since {
		float: none;
		text-align: center;
	}
}
.since {
	&-text {
		margin-bottom: 30px;
	}
}
.contact {
	&-item {
		margin: 15px 0 0 0;
		width: 100%;
		display: block;
	}
}
