.doorTypes {
	padding: 0 0 7px 0;
	background-color: $brand;
	&-nav {
		font-size: 0;
		margin: 0 -15px;
	}
	&-item {
		width: 33.333%;
		display: inline-block;
		vertical-align: bottom;
	}
	&-link {
		color: $main;
		background-color: $snowy;
		display: block;
		// height: 155px;
		@include transition(color 300ms, background-color 300ms);
		.blockTitle::after {
			background-color: $brand;
			@include transition(background-color 300ms);
		}
		.no-touch &:hover {
			color: $snowy;
			background-color: transparent;
			.blockTitle::after {
				background-color: $snowy;
			}
			.doorTypes-intro {
				border-color: transparent;
			}
		}
	}
	&-intro {
		@include transition(border-color 300ms);
		padding: 25px 40px 23px 15px;
		border-right: 1px solid #efefef;
		height: 160px;
		overflow: hidden;
		&__large {
			padding: 35px 40px 23px 15px;
			height: 170px;
		}
	}
}
.advantages {
	margin: 70px -15px 0;
	font-size: 0;
}
.advantage {
	width: 33%;
	display: table-cell;
	vertical-align: middle;
	height: 130px;
	background-color: rgba(0,0,0,.4);
	background-blend-mode: multiply;
	background-size: cover;
	color: $snowy;
	padding: 10px 50px;
	&__overflow {
		position: relative;
		top: -10px;
	}
	.blockTitle {
		margin: 0;
		padding: 0;
		&::after {
			display: none;
		}
	}
}
// .testimonals {
// 	position: relative;
// }
.reviews {
	margin: 0 -15px;
}

.review {
	padding: 0 35px 0 15px;
}
.partners {
	padding-top: 70px;
}
.partner {
	width: 33.33%;
	float: left;
	//height: 100px;
	//overflow: hidden;
	padding: 0 30px;
	margin-top: 50px;
	// &-img {
	// 	@extend %fit-contain-img;
	// }
}
.address {
	font-family: $rBold;
	@include fontSize(15);
}
.home-contact {
	position: absolute;
	left: calc(50vw - 765px);
	padding: 25px 30px 0;
	top: 60px;
	//@include translate(0, -50%);
	//margin-top: -14%;
	width: 28%;
	.sectionTitle {
		padding: 0;
		margin-bottom: 25px;
		&::after {
			display: none;
		}
	}
	&-item {
		margin-bottom: 25px;
	}
	.paragraph {
		padding-left: 0;
	}

}
